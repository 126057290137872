.navbar {
  position: fixed;
  z-index: 2;
  width: 100%;
  top: 0;

  header {
    background: #000;
    color: #fff;
    padding: 5px 15px;

    > div {
      display: flex;
      justify-content: space-between;
    }

    .call-info {
      display: flex;
      gap: 100px;

      a {
        text-decoration: none;
        color: #fff;
        transition: .5s;

        &:hover {
          color: var(--brend-color);
        }
      }
    }

    .network {
      display: flex;
      gap: 50px;

      a {
        color: #fff;

        svg {
          font-size: 20px;
        }
      }
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  nav {
    background: #fff;
    padding: 0 15px;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .logo {
      img {
        width: 60px;
      }
    }

    .menu {
      cursor: pointer;
      width: 40px;

      :nth-child(2) {
        margin: 10px 0;
      }

      div {
        width: 40px;
        height: 3px;
        background: #000;
      }
    }

    ul {
      display: flex;
      gap: 50px;
      padding-inline-start: 0;

      li {
        list-style: none;

        a {
          text-decoration: none;
          color: #000;
          font-size: 16px;
          font-weight: 600;
          transition: .5s;

          &:hover {
            color: var(--brend-color);
          }
        }
      }
    }

    @media (max-width: 768px) {
      ul {
        background: rgba(0, 0, 0, 0.9);
        position: fixed;
        display: block;
        right: -200%;
        top: 0;
        height: 100%;
        z-index: 2;
        margin-top: 0;
        width: 100%;
        transition: .5s;
        padding-top: 100px;
        padding-left: 30px;
        text-align: center;
        li {
          a {
            color: #fff;
            font-size: 35px;
            font-weight: 400;
            line-height: 100px;
          }
        }

        &.active {
          right: 0;
          box-sizing: content-box;
        }
      }
    }
    @media (min-width: 768px) {
      .menu {
        display: none;
      }
    }
  }
}

.bar1,
.bar2,
.bar3 {
  transition: 0.4s;
}
.open {
  z-index: 4;
}
.open .bar1 {
  transform: rotate(-45deg) translate(-11px, 6px);
  background: #fff !important;
}

.open .bar2 {
  opacity: 0;
}

.open .bar3 {
  transform: rotate(45deg) translate(-12px, -6px);
  background: #fff !important;
}
