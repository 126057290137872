.footer {
  background: #282828;

  .foooter-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap: 50px;
    padding: 100px 30px;

    div {
      width: 100%;

      h3 {
        font-size: 23px;
        color: #fff;
        margin-top: 0;
        font-weight: 600;
        text-transform: capitalize;
      }

      p {
        color: #fff;
        font-size: 14px;
      }

      ul {
        padding-inline-start: 0;

        li {
          list-style: none;

          a {
            color: #fff;
            text-decoration: none;
            line-height: 30px;
            transition: .5s;
            font-size: 14px;

            &:hover {
              color: var(--brend-color);
            }
          }
        }
      }
    }
    img {
      width: 100px;
    }
  }

  .copyright {
    background: #000;
    padding: 20px 0;
    color: #fff;
    text-align: center;
  }
}
