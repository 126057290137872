:root {
  --brend-color: #ecdbc1;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #f7f7f7;
}

.App {
  overflow: hidden;
}

* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}

.btn {
  text-decoration: none;
  color: #000;
  background: var(--brend-color);
  padding: 20px 30px;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 50px;
  transition: .5s;
  border: 1px solid var(--brend-color);
}

p {
  font-size: 18px;
}

.btn:hover {
  background: transparent;
}
.btn.white:hover {
  color: #fff;
}
.title {
  font-size: 40px;
  font-weight: bold;
}

.title.white {
  color: #fff;
}

.wrapper {
  width: 1300px;
  padding: 0 20px 0 20px;
  margin: 0 auto;
}

.App {
  opacity: 0;
}

.App.visible {
  animation: transitionIn 1s forwards;
}

@keyframes transitionIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1300px) {
  .wrapper {
    width: 100%;
  }

  .title {
    font-size: 30px;
  }
}

.header-img-title {
  position: relative;
  margin-top: 95px;
  margin-bottom: 100px;

  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .bg {
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 99%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    margin-top: 100px;
  }
}

@media (max-width: 768px) {
  p {
    font-size: 18px;
  }
}
