.vertical-tabs {
  display: flex;
  background: #fff;
  border-radius: 20px;
  margin-bottom: 100px;
}

.tabs {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.tab {
  padding: 15px 10px;
  cursor: pointer;
  border-right: 1px solid #ddd;
  border-radius: 20px;
  text-align: center;
  transition: background-color 0.3s, transform 0.3s;
  font-weight: bold;

  &:hover {
    background-color: #ddd;
  }

  &.active {
    background-color: var(--brend-color);
  }
}

.content {
  padding: 20px;
  width: 100%;
  flex-grow: 1;
  animation: fadeIn 0.5s ease-in-out;

  div {
    font-size: 18px;
    line-height: 1.6;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 700px) {
  .vertical-tabs {
    flex-direction: column;

    .tabs {
      width: 100%;
    }
  }
}
