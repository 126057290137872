.info-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 50px;
  margin-bottom: 100px;

  div {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 60px 30px;
    border-radius: 20px;

    svg {
      font-size: 60px;
      color: var(--brend-color);
      margin-bottom: 20px;
    }

    span {
      font-weight: bold;
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
  }
}

.about-content {
  display: grid;
  gap: 50px;
  margin: 100px auto;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

  .video {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }
}

.video {
  position: relative;
  display: inline-block;
  width: 100%;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3em;
  color: #fff;
  cursor: pointer;
}

video {
  height: 80vh;
}

.wood {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  justify-content: center;
  gap: 50px;
  margin-bottom: 100px;

  > div {
    overflow: hidden;
    width: 100%;
    height: 270px;
    position: relative;
    border-radius: 20px;

    .wood-bg {
      position: absolute;
      background: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: 1s;

      span {
        color: #fff;
        font-size: 25px;
        font-weight: bold;
      }
    }

    &:hover .wood-bg {
      opacity: 1;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
