.form {
  display: grid;
  margin: 50px auto;
  gap: 50px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  .form-info {
    display: grid;
    grid-template-columns: auto;
    gap: 20px;

    div {
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 20px;

      svg {
        font-size: 60px;
        color: var(--brend-color);
      }

      p,
      a {
        font-size: 18px;
        font-weight: bold;
        margin: 18px 0;
        color: #000;
        text-decoration: none;
      }
    }
  }

  form {
    div {
      margin-bottom: 30px;
    }
    label {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
    }
    input,
    textarea {
      display: block;
      width: 100%;
      padding: 20px;
      border-radius: 20px;
      border: none;
      margin-top: 5px;
      box-shadow: 0 0 10px -12px rgba(0, 0, 0, 0.5);
      outline: none;
      font-size: 16px;
      font-weight: 500;

      &::placeholder {
        text-transform: uppercase;
        font-size: 12px;
      }
    }

    textarea {
      resize: none;
      height: 300px;
    }

    button {
      cursor: pointer;
    }
  }

  @media (max-width: 900px) {
    grid-template-columns: auto;

    .form-info {
      div {
        padding: 50px 10px;
      }
    }
  }
}

.error {
  margin: 0;
  color: red;
  font-size: 12px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.modal-content p {
  margin-bottom: 20px;
}

.modal-content button {
  padding: 10px 20px;
  background: var(--brend-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
