.lets {
  position: relative;
  height: 500px;
  margin-top: 100px;
  margin-bottom: 100px;

  .text {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: 50px;
      font-weight: bold;
      color: #fff;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 900px) {
    .text {
      h3 {
        font-size: 30px;
      }
    }
  }
}
