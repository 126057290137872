.home-banner {
  position: relative;
  height: 100vh;
  margin-bottom: 100px;

  .banner-text {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;

    div {
      width: 800px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      h1 {
        color: #fff;
        font-size: 80px;
        font-weight: bold;
        margin-bottom: 0;
      }

      p {
        color: #fff;
        font-size: 20px;
        font-weight: 300;
        margin-bottom: 50px;
      }
    }

    @media (max-width: 900px) {
      div {
        width: 100%;
        padding: 0 20px;
        margin-top: 50px;

        h1 {
          font-size: 30px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
}

.gallery {
  margin-top: 100px;

  .row {
    display: flex;
    gap: 50px;

    .row-img {
      position: relative;

      img {
        width: 100%;
        border-radius: 20px;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .row1 {
    display: flex;
    gap: 50px;

    img {
      width: 100%;
    }
  }

  @media (max-width: 900px) {
    p {
      font-size: 16px;
    }

    .row {
      flex-direction: column;
    }
  }
}

.about {
  margin-top: 100px;
  .row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 50px;

    img {
      width: 100%;
      border-radius: 20px;
    }

    :nth-child(2) {
      width: 100%;
    }
  }

  @media (max-width: 900px) {
    .row {
      flex-direction: column;
    }
  }
}
